import { AnyAction, Dispatch, Middleware } from "@reduxjs/toolkit";
import { cancelDownload, downloadFile, DownloadStatus, getDownloadStatus, removeDownloadStatusEntry, setDownloadStatus } from "./ProjectsSlice";
import { generateFileDownloadLink, ProjectEntity } from "./ProjectsAPI";
import { Directory, Filesystem, ProgressListener } from "@capacitor/filesystem";
import { isAndroid, isIOS, isMedia, isVideo } from "../../app/utils";
import { Media } from "@capacitor-community/media";

class DownloadQueue {
  private queue: (() => Promise<void>)[] = []
  private currentInExecution = 0

  async add(element: () => Promise<void>) {
    this.queue.push(element)
    await this.start()
  }
  private async start() {
    let fsPermission = await Filesystem.checkPermissions();
    const concurrentBound = fsPermission.publicStorage === "granted" && !isAndroid() ? 4 : 2
    while (this.currentInExecution < concurrentBound && this.queue.length > 0) {
      this.currentInExecution++
      const oldest = this.queue.shift()
      oldest?.()
        .finally(() => {
          this.currentInExecution--
          this.start()
        })
    }
  }
}
export const downloadMiddleware: Middleware<any, any, Dispatch<AnyAction>> = store => {
  const mainDownloadQueue = new DownloadQueue()
  const downloadProgressHandlerFactory: (
    downloadUrl: string,
    repoId: string,
    folder: string,
    filename: string
  ) => ProgressListener =
    (downloadUrl, repoId, folder, filename) => async (event) => {
      if (event.url === downloadUrl) {
        store.dispatch(setDownloadStatus({
          repoId,
          folder,
          filename,
          status: {
            downloadedBytes: event.bytes,
            totalBytes: event.contentLength,
            state: 'downloading'
          },
        }));
      }
    }
  return next => async action => {
    if(downloadFile.fulfilled.match(action)) {
      const {
        originalRepoId, originalFolder, file, path, directory,
        repoId, folder, filename, sessionToken, location
      } = action.payload
      const downloadUrl: string = await generateFileDownloadLink(
        repoId, folder, filename, sessionToken
      );
      const downloadProgressHandler = downloadProgressHandlerFactory(
        downloadUrl,
        repoId,
        folder,
        filename
      )
      await mainDownloadQueue.add(async () => {
        const state = await store.getState()
        const status = getDownloadStatus(location)(state)
        if (status?.state === 'downloading') {
          store.dispatch(setDownloadStatus({
            repoId,
            folder,
            filename,
            status: {
              downloadedBytes: 1,
              totalBytes: 100000,
              state: 'downloading'
            },
          }));
          const listener = await Filesystem.addListener(
            "progress",
            downloadProgressHandler
          );
          try {
            await Filesystem.downloadFile({
              path,
              url: downloadUrl,
              directory,
              progress: true,
              readTimeout: 7000,
            })
            .finally(() => listener.remove())
            if (isMedia(filename)) await storeAsMedia(filename, path, directory);
            store.dispatch(removeDownloadStatusEntry(location));
          } catch (error) {
            const state = await store.getState()
            const status = getDownloadStatus(location)(state)
            if (status?.state === 'canceled') {
              store.dispatch(removeDownloadStatusEntry(location));
            } else {
              store.dispatch(setDownloadStatus({
                repoId,
                folder,
                filename,
                status: failedStatus(originalRepoId, originalFolder, file, error as Error)
              }));
            }
          }
        }
      })
    } else if (cancelDownload.match(action)) {
      // TODO: stop running download
    }
    return next(action)
  }
}

function failedStatus(
  repo: string, folder: string, entity: ProjectEntity, error: Error
): DownloadStatus {
  return {
    downloadedBytes: 0,
    totalBytes: 0,
    error: error as Error,
    state: 'canceled',
    data: {
      repo,
      folder,
      entity
    }
  }
}

async function createAlbum(name: string) {
  await Media.createAlbum({name: name});
  const { albums } = await Media.getAlbums();
  return albums?.find(x => x.name === name);
}

async function getAlbumIdentifier(name: string): Promise<string|undefined> {
  const { albums } = await Media.getAlbums();
  let albumIdentifier: string|undefined = albums?.find(x => x.name === name)?.identifier;
  if (!albumIdentifier) {
    const album = await createAlbum(name);
    albumIdentifier = album?.identifier;
  }
  return albumIdentifier;
}

async function storeAsMedia(filename: string, path: string, directory: Directory){
  const storedFile = await Filesystem.stat({path, directory});
  const options = {
    path: storedFile?.uri,
    albumIdentifier: isIOS() ? undefined : await getAlbumIdentifier('Meraki')
  };
  if (isVideo(filename)) {
    await Media.saveVideo(options);
  } else {
    await Media.savePhoto(options);
  }
  await Filesystem.deleteFile({
    path: storedFile?.uri
  });
}